const RoleRequestSentMessage = ({ className }) => (
  <small className={`has-text-weight-light ${className}`}>
    <center>
      Your access request has been submitted, please contact Coral Huber
      (coral.j.huber@usace.army.mil) for approval.
    </center>
  </small>
);

export default RoleRequestSentMessage;
