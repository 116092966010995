import { mdiCopyright } from '@mdi/js';

import CuiBanner from '../cui-banner';
import Icon from '@components/icon/icon';

import './footer.scss';

const Footer = () => (
  <div className='fixed-bottom'>
    <footer aria-label='Site Footer' className='page-footer'>
      <div className='footer'>
        <div className='text-center'>
          <p>
            <strong>
              <Icon path={mdiCopyright} />
            </strong>{' '}
            U.S. Army Corps of Engineers {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </footer>
    <CuiBanner />
  </div>
);

export default Footer;
